import QS from 'qs'
import {
  keys,
  map,
  toString,
  innerJoin,
  equals,
  pipe,
  omit,
  complement,
  pick,
  toPairs,
  mapObjIndexed,
  mergeAll,
  either,
  isNil,
  isEmpty,
  compose,
  all,
  prop,
  length,
  reject,
  path,
  filter,
  fromPairs,
} from 'ramda'

import {
  FilterStaticType,
  FilterStaticFilterQueryParamsType,
  FilterStaticRequestValuesType,
  StaticDataItemType,
  SortType,
  FilterStaticDataType,
  FilterStaticBaseConfigType,
  FilterStaticFieldNamesType,
} from 'src/models'

import { formatDate, getStringId, isNotNilOrNotEmpty, messages } from 'src/utils'

export const getParamsFromQueryStr = (query?: FilterStaticFilterQueryParamsType): FilterStaticFilterQueryParamsType => {
  const initialQuery = query as Record<string, string>
  const queryObj = QS.parse(initialQuery, { parseArrays: true })
  return queryObj || null
}

export const getFilterTitleIsVisible = complement(compose(isEmpty, reject(equals('date')), keys))
export const getFilterOptionsIsEmpty = compose(all(isEmpty), map(prop('options')), Object.values)
export const getFilterOptionsIsNotEmpty = complement(getFilterOptionsIsEmpty)
export const getFilterActiveOptionsCount = compose(length, keys)

export const itemEqualById = (item: StaticDataItemType, id: string) => equals(toString(item?.id), id)

export const itemEqualByValue = (item: StaticDataItemType, value: string) => equals(item?.value, value)

export const prepareFiltersToRequest = map(getStringId)
export const getSelectedItemById: any = innerJoin(itemEqualById)
export const getSelectedItemByValue: any = innerJoin(itemEqualByValue)

export const getFilterValue = (options: StaticDataItemType[] = [], value?: string | string[]) => {
  const isNotId = Array.isArray(value) ? value.some((v) => isNaN(Number(v))) : isNaN(Number(value))

  return isNotId ? getSelectedItemByValue(options, [value]) : getSelectedItemById(options, value)
}

export const createFilterStaticBaseConfig = (
  filterItems: FilterStaticFieldNamesType[],
  data?: FilterStaticDataType,
): FilterStaticBaseConfigType => {
  const options: FilterStaticBaseConfigType = {
    sectors: {
      name: data?.sectors?.name || '',
      placeholder: data?.sectors?.placeholder || messages.select.placeholder,
      options: data?.sectors?.options || [],
      variant: data?.sectors?.variant || 'collapsed-checklist',
      isMulti: !!data?.sectors?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.sectors?.isVisible) ? data?.sectors?.isVisible : true,
    },
    zones: {
      name: data?.zones?.name || '',
      placeholder: data?.zones?.placeholder || messages.select.placeholder,
      options: data?.zones?.options || [],
      variant: data?.zones?.variant || 'collapsed-checklist',
      isMulti: !!data?.zones?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.zones?.isVisible) ? data?.zones?.isVisible : true,
    },
    planVisits: {
      name: data?.planVisits?.name || '',
      placeholder: data?.planVisits?.placeholder || messages.select.placeholder,
      options: data?.planVisits?.options || [],
      variant: data?.planVisits?.variant || 'collapsed-checklist',
      isMulti: !!data?.planVisits?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.planVisits?.isVisible) ? data?.planVisits?.isVisible : true,
    },
    customerStatus: {
      name: data?.customerStatus?.name || '',
      placeholder: data?.customerStatus?.placeholder || messages.select.placeholder,
      options: data?.customerStatus?.options || [],
      variant: data?.customerStatus?.variant || 'collapsed-checklist',
      isMulti: !!data?.customerStatus?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.customerStatus?.isVisible) ? data?.customerStatus?.isVisible : true,
    },
    customers: {
      name: data?.customers?.name || '',
      placeholder: data?.customers?.placeholder || messages.select.placeholder,
      options: data?.customers?.options || [],
      variant: data?.customers?.variant || 'multi-select',
      isMulti: !!data?.customers?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.customers?.isVisible) ? data?.customers?.isVisible : true,
    },
    quantityEmployees: {
      name: data?.quantityEmployees?.name || '',
      placeholder: data?.quantityEmployees?.placeholder || messages.select.placeholder,
      options: data?.quantityEmployees?.options || [],
      variant: data?.quantityEmployees?.variant || 'collapsed-checklist',
      isMulti: !!data?.quantityEmployees?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.quantityEmployees?.isVisible) ? data?.quantityEmployees?.isVisible : true,
    },
    statuses: {
      name: data?.statuses?.name || '',
      placeholder: data?.statuses?.placeholder || messages.select.placeholder,
      options: data?.statuses?.options || [],
      variant: data?.statuses?.variant || 'collapsed-checklist',
      isMulti: !!data?.statuses?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.statuses?.isVisible) ? data?.statuses?.isVisible : true,
    },
    types: {
      name: data?.types?.name || '',
      placeholder: data?.types?.placeholder || messages.select.placeholder,
      options: data?.types?.options || [],
      variant: data?.types?.variant || 'checklist',
      isMulti: !!data?.types?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.types?.isVisible) ? data?.types?.isVisible : true,
    },
    languages: {
      name: data?.languages?.name || '',
      placeholder: data?.languages?.placeholder || messages.select.placeholder,
      options: data?.languages?.options || [],
      variant: data?.languages?.variant || 'checklist',
      isMulti: !!data?.languages?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.languages?.isVisible) ? data?.languages?.isVisible : true,
    },
    navigator: {
      name: data?.navigator?.name || '',
      placeholder: data?.navigator?.placeholder || messages.select.placeholder,
      options: data?.navigator?.options || [],
      variant: data?.navigator?.variant || 'checklist',
      isMulti: !!data?.navigator?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.navigator?.isVisible) ? data?.navigator?.isVisible : true,
    },
    parcelsStatus: {
      name: data?.parcelsStatus?.name || '',
      placeholder: data?.parcelsStatus?.placeholder || messages.select.placeholder,
      options: data?.parcelsStatus?.options || [],
      variant: data?.parcelsStatus?.variant || 'collapsed-checklist',
      isMulti: !!data?.parcelsStatus?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.parcelsStatus?.isVisible) ? data?.parcelsStatus?.isVisible : true,
    },
    parcelArticleStatus: {
      name: data?.parcelArticleStatus?.name || '',
      placeholder: data?.parcelArticleStatus?.placeholder || messages.select.placeholder,
      options: data?.parcelArticleStatus?.options || [],
      variant: data?.parcelArticleStatus?.variant || 'collapsed-checklist',
      isMulti: !!data?.parcelArticleStatus?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.parcelArticleStatus?.isVisible) ? data?.parcelArticleStatus?.isVisible : true,
    },
    businessType: {
      name: data?.businessType?.name || '',
      placeholder: data?.businessType?.placeholder || messages.select.placeholder,
      options: data?.businessType?.options || [],
      variant: data?.businessType?.variant || 'collapsed-checklist',
      isMulti: !!data?.businessType?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.businessType?.isVisible) ? data?.businessType?.isVisible : true,
    },
    customerLabel: {
      name: data?.customerLabel?.name || '',
      placeholder: data?.customerLabel?.placeholder || messages.select.placeholder,
      options: data?.customerLabel?.options || [],
      variant: data?.customerLabel?.variant || 'collapsed-checklist',
      isMulti: !!data?.customerLabel?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.customerLabel?.isVisible) ? data?.customerLabel?.isVisible : true,
    },
    plannedVisitsExist: {
      name: data?.plannedVisitsExist?.name || '',
      placeholder: data?.plannedVisitsExist?.placeholder || messages.select.placeholder,
      options: data?.plannedVisitsExist?.options || [],
      variant: data?.plannedVisitsExist?.variant || 'collapsed-checklist',
      isMulti: !!data?.plannedVisitsExist?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.plannedVisitsExist?.isVisible) ? data?.plannedVisitsExist?.isVisible : true,
    },
    profileExists: {
      name: data?.profileExists?.name || '',
      placeholder: data?.profileExists?.placeholder || messages.select.placeholder,
      options: data?.profileExists?.options || [],
      variant: data?.profileExists?.variant || 'collapsed-checklist',
      isMulti: !!data?.profileExists?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.profileExists?.isVisible) ? data?.profileExists?.isVisible : true,
    },
    potentials: {
      name: data?.potentials?.name || '',
      placeholder: data?.potentials?.placeholder || messages.select.placeholder,
      options: data?.potentials?.options || [],
      variant: data?.potentials?.variant || 'collapsed-checklist',
      isMulti: !!data?.potentials?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.potentials?.isVisible) ? data?.potentials?.isVisible : true,
    },
    parcelsType: {
      name: data?.parcelsType?.name || '',
      placeholder: data?.parcelsType?.placeholder || messages.select.placeholder,
      options: data?.parcelsType?.options || [],
      variant: data?.parcelsType?.variant || 'collapsed-checklist',
      isMulti: !!data?.parcelsType?.isMulti,
      isVisible: isNotNilOrNotEmpty(data?.parcelsType?.isVisible) ? data?.parcelsType?.isVisible : true,
    },
    date: {
      name: data?.date?.name || '',
      placeholder: data?.date?.placeholder || messages.select.placeholder,
      variant: data?.date?.variant || 'date',
      isVisible: isNotNilOrNotEmpty(data?.date?.isVisible) ? data?.date?.isVisible : true,
    },
    search: {
      name: data?.search?.name || '',
      variant: data?.search?.variant || 'search',
      placeholder: data?.search?.placeholder || '',
      isVisible: isNotNilOrNotEmpty(data?.search?.isVisible) ? data?.search?.isVisible : true,
    },
  }

  const isVisibleTrue = (key: FilterStaticFieldNamesType) => path(['isVisible'])(options[key]) as any
  const getKeys = (key: FilterStaticFieldNamesType) => [key, options[key]] as any

  const filterVisibleOptions = compose(fromPairs, map(getKeys), filter(isVisibleTrue))

  return pick(filterItems, filterVisibleOptions(filterItems)) as FilterStaticBaseConfigType
}

const isExist = complement(either(isNil, isEmpty))

const getFiltersRequestItem = (
  key: keyof FilterStaticRequestValuesType & keyof Omit<FilterStaticDataType, 'search' | 'date'>,
  data?: FilterStaticType,
  filterItems?: FilterStaticDataType,
) => {
  return (
    isExist(data?.[key]) && {
      [key]: filterItems?.[key]?.isMulti
        ? prepareFiltersToRequest((data?.[key] || []) as StaticDataItemType[])
        : prepareFiltersToRequest((data?.[key] || []) as StaticDataItemType[])?.[0],
    }
  )
}

const getFiltersRequestItems = (data?: FilterStaticType, filterItems?: FilterStaticDataType) => {
  return mergeAll(
    pipe(omit(['search', 'date']), keys)(data).map((key) => getFiltersRequestItem(key as any, data, filterItems)),
  )
}

export const mapFiltersStaticRequestData = (
  data: FilterStaticType,
  filterItems?: FilterStaticDataType,
): FilterStaticRequestValuesType => {
  return {
    ...getFiltersRequestItems(data, filterItems),
    ...(data?.search && { search: data.search }),
    ...(data?.startDate && { startDate: formatDate(data.startDate, 'dashReversed') }),
    ...(data?.endDate && { endDate: formatDate(data.endDate, 'dashReversed') }),
  }
}

const getFilterInitialItem = (
  key: keyof FilterStaticRequestValuesType & keyof Omit<FilterStaticDataType, 'search'>,
  data?: FilterStaticRequestValuesType,
  filterItems?: FilterStaticDataType,
) => {
  return {
    ...(data?.[key]
      ? {
          [key]: getFilterValue(filterItems?.[key]?.options || [], data[key]),
        }
      : {}),
  }
}

const extractSelectedOptions = (data?: FilterStaticRequestValuesType, filterItems?: FilterStaticDataType) => {
  return mergeAll(
    pipe(
      omit(['search', 'startDate', 'endDate']),
      keys,
    )(data).map((key) => getFilterInitialItem(key as any, data, filterItems)),
  )
}

export const prepareFilterStaticInitialValues = (
  data?: FilterStaticRequestValuesType,
  filterItems?: FilterStaticDataType,
): FilterStaticType | null => {
  return {
    ...extractSelectedOptions(data, filterItems),
    ...(data?.search && { search: data.search }),
    ...(data?.startDate && { startDate: new Date(data.startDate) }),
    ...(data?.endDate && { endDate: new Date(data.endDate) }),
  }
}

export const convertSortParametersToSortingRules = (sortQuery?: string | number | SortType) => {
  if (sortQuery && typeof sortQuery === 'object' && toPairs(sortQuery).length) {
    return mapObjIndexed((value) => value, sortQuery)
  }
  return {}
}
