import { FiltersDrawer } from 'src/components/common'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useCustomersListFetch, useCustomersFilters } from 'src/bus/customers'

import { useUrlQuery } from 'src/hooks'

import { useDrawer } from 'src/contexts'

import { GET_ENUMS } from 'src/utils'

export const CustomersDrawer = () => {
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { customersFilters, loadingCustomersFiltersFetch } = useCustomersFilters()
  const { onRefetchCustomersList, customersFiltersStaticInitialValues, loadingCustomersListFetch } =
    useCustomersListFetch()

  const filterStaticItems: FilterStaticDataType = {
    businessType: {
      name: FilterStaticEnum.businessType,
      options: customersFilters.businessType,
      isMulti: true,
    },
    customerLabel: {
      name: FilterStaticEnum.customerLabel,
      options: customersFilters.customerLabels,
      isMulti: true,
    },
    customerStatus: {
      name: FilterStaticEnum.customerStatus,
      options: customersFilters.customerStatus,
      isMulti: true,
    },
    planVisits: {
      name: FilterStaticEnum.planVisits,
      options: customersFilters.planVisits,
    },
    potentials: {
      name: FilterStaticEnum.potentials,
      options: customersFilters.potential,
      isMulti: true,
    },
    quantityEmployees: {
      name: FilterStaticEnum.quantityEmployees,
      options: customersFilters.quantityEmployees,
      isMulti: true,
    },
    sectors: {
      name: FilterStaticEnum.sectors,
      options: customersFilters.sectors,
      isMulti: true,
    },
    zones: {
      name: FilterStaticEnum.zones,
      options: customersFilters.zones,
      isMulti: true,
    },
  }

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchCustomersList(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <FiltersDrawer
      onSubmit={onSubmit}
      filterItemsSet={filterStaticItems}
      buttons={['apply', 'reset']}
      loadingSubmit={loadingCustomersListFetch}
      initialValues={customersFiltersStaticInitialValues?.filter}
      loadingStaticDataFetch={loadingCustomersFiltersFetch}
    />
  )
}
