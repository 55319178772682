import { useLayoutEffect, useState } from 'react'
import { prepareFilterStaticInitialValues } from 'utils/filters'
import { isNilOrEmpty } from 'utils/ramda-helpers'

import { TagCheckable } from 'src/components/common'

import {
  FilterStaticDataType,
  FilterStaticRequestValuesType,
  StaticDataItemsType,
  StaticDataItemType,
} from 'src/models'

type SelectQuickFiltersProps = {
  filters: StaticDataItemsType
  initialValues?: FilterStaticRequestValuesType | null
  filterItemsSet: FilterStaticDataType
  onChange?: (plannedVisitsExist: StaticDataItemType[]) => void
  loading?: boolean
}

export const SelectQuickFilters = ({
  loading = false,
  filters,
  initialValues,
  filterItemsSet,
  onChange,
}: SelectQuickFiltersProps) => {
  const [selected, setSelected] = useState<string>()

  const onHandleChange = (item: StaticDataItemType) => {
    if (selected === item?.label) {
      setSelected(undefined)
      onChange?.([])
    } else {
      setSelected(item?.label)
      onChange?.([item])
    }
  }

  useLayoutEffect(() => {
    if (isNilOrEmpty(initialValues)) {
      setSelected(undefined)
      return
    }

    const data = initialValues && prepareFilterStaticInitialValues(initialValues, filterItemsSet)

    const plannedVisitsExist = data?.plannedVisitsExist?.length !== 0 ? data?.plannedVisitsExist?.[0]?.label : undefined

    setSelected(plannedVisitsExist)
  }, [initialValues, filterItemsSet])

  return (
    <>
      {filters?.plannedVisitsExistence?.map((item) => {
        return (
          <TagCheckable
            key={item.id}
            label={item.label}
            onChange={() => onHandleChange(item)}
            checked={selected === item.label}
            disabled={loading}
          />
        )
      })}
    </>
  )
}
