import { StaticDataItemsType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type ManagePointsStaticDataFetchType = {
  managePointsFilters: StaticDataItemsType
  loadingCustomerStaticDataFetch: boolean
}

export const useManagePointsStaticDataFetch = (): ManagePointsStaticDataFetchType => {
  const staticDataKeys = [
    StaticDataKeys.acceptance,
    StaticDataKeys.customerStatus,
    StaticDataKeys.potential,
    StaticDataKeys.businessType,
    StaticDataKeys.quantityEmployees,
    StaticDataKeys.plannedVisitsExistence,
  ]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const managePointsFilters = {
    customerStatus: staticData?.['customer-status'] || [],
    profileExists: staticData?.['acceptance'] || [],
    potential: staticData?.['potential'] || [],
    businessType: staticData?.['business-type'] || [],
    quantityEmployees: staticData?.['quantity-employees'] || [],
    plannedVisitsExistence: staticData?.['planned-visits-existence'] || [],
  }

  return {
    loadingCustomerStaticDataFetch: loadingStaticDataFetch,
    managePointsFilters,
  }
}
