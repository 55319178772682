import { StaticDataItemsType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'
import { useVisitsHistoryCustomersFetch, useVisitsHistorySectorsFetch } from 'src/bus/visitsHistory'

type UseVisitsHistoryStaticDataFetchType = {
  visitsHistoryFilters: StaticDataItemsType
  loadingVisitsStaticDataFetch: boolean
}

export const useVisitsHistoryStaticDataFetch = (): UseVisitsHistoryStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.visitStatus, StaticDataKeys.customerStatus]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)
  const { visitsHistorySectors, loadingVisitsHistorySectorsFetch } = useVisitsHistorySectorsFetch()
  const { visitsHistoryCustomers, loadingVisitsHistoryCustomersFetch } = useVisitsHistoryCustomersFetch()

  const visitsHistoryFilters = {
    visitStatus: staticData?.['visit-status'] || [],
    customerStatus: staticData?.['customer-status'] || [],
    sectors: visitsHistorySectors.items || [],
    customers: visitsHistoryCustomers.items || [],
  }

  return {
    visitsHistoryFilters,
    loadingVisitsStaticDataFetch:
      loadingStaticDataFetch || loadingVisitsHistorySectorsFetch || loadingVisitsHistoryCustomersFetch,
  }
}
