import { StaticDataItemsType } from 'src/models'

import { useManagePointsStaticDataFetch } from 'src/bus/manageTours'
import { useUserSectorsFetch, useUserZonesFetch } from 'src/bus/userOptions'

type ManagePointsFiltersType = {
  loadingManagePointsFiltersFetch: boolean
  managePointsFilters: StaticDataItemsType
}

export const useManagePointsFilters = (): ManagePointsFiltersType => {
  const { managePointsFilters, loadingCustomerStaticDataFetch } = useManagePointsStaticDataFetch()
  const { sectors, loadingUserSectorsFetch } = useUserSectorsFetch()
  const { zones, loadingUserZonesFetch } = useUserZonesFetch()

  const filters = {
    ...managePointsFilters,
    sectors,
    zones,
  }

  return {
    managePointsFilters: filters,
    loadingManagePointsFiltersFetch: loadingCustomerStaticDataFetch || loadingUserSectorsFetch || loadingUserZonesFetch,
  }
}
