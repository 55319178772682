import { REGEXP_INTEGER, REGEXP_PWD_SPACE, REGEXP_SIGN_FORMAT } from 'src/utils/constants'

interface IGeneral {
  [key: string]: string
}

const generalWithTranslations: IGeneral = {
  required: 'fields:error_messages.required',
  requiredOneOf: 'fields:error_messages.required_one_of',
  integer: 'fields:error_messages.integer',
  errorMin: 'fields:error_messages.min',
  errorMax: 'fields:error_messages.max',
  errorLength: 'fields:error_messages.length',
  emailInvalid: 'fields:error_messages.invalid_email',
  invalidPwdWhitespaces: 'fields:error_messages.invalid_pwd_whitespaces',
  invalidSign: 'fields:error_messages.invalid_sign',
  imagesMin: 'fields:error_messages.images_min',
  defaultSelectPlaceholder: 'fields:default_placeholders.select',
}

const getFieldLabel = (key: string, placeholder = false): string =>
  `fields:${key}.${placeholder ? 'placeholder' : 'label'}`

export const messages = {
  login: {
    label: '',
    placeholder: getFieldLabel('login', true),
    required: generalWithTranslations.required,
  },
  password: {
    label: '',
    placeholder: getFieldLabel('password', true),
    required: generalWithTranslations.required,
    min: 8,
    minText: generalWithTranslations.errorMin,
    invalidWhiteSpaces: generalWithTranslations.invalidPwdWhitespaces,
    patternWhitespaces: REGEXP_PWD_SPACE,
  },
  select: {
    label: '',
    placeholder: generalWithTranslations.defaultSelectPlaceholder,
    required: generalWithTranslations.required,
  },
  vat: {
    label: getFieldLabel('vat'),
    placeholder: getFieldLabel('vat', true),
    required: generalWithTranslations.required,
  },
  cancelVisit: {
    label: '',
    placeholder: getFieldLabel('comment', true),
    required: generalWithTranslations.required,
    min: 5,
    minText: generalWithTranslations.errorMin,
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  stopTour: {
    label: '',
    placeholder: getFieldLabel('reason', true),
    required: generalWithTranslations.required,
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  comment: {
    label: '',
    placeholder: getFieldLabel('comment', true),
    required: generalWithTranslations.required,
    max: 512,
    maxText: generalWithTranslations.errorMax,
    min: 5,
    minText: generalWithTranslations.errorMin,
  },
  createVisitComment: {
    label: '',
    placeholder: getFieldLabel('create_visit_comment', true),
    required: generalWithTranslations.required,
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  fillComplaint: {
    label: '',
    placeholder: getFieldLabel('reason', true),
    required: generalWithTranslations.required,
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  note: {
    label: '',
    placeholder: getFieldLabel('note', true),
    required: generalWithTranslations.required,
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  date: {
    label: '',
    required: generalWithTranslations.required,
  },
  search: {
    label: '',
    placeholder: getFieldLabel('search', true),
    max: 50,
    maxText: generalWithTranslations.errorMax,
  },
  searchHistory: {
    label: '',
    placeholder: getFieldLabel('search_history', true),
  },
  parcelsArticlesSearch: {
    label: '',
    placeholder: getFieldLabel('parcels_articles_search', true),
  },
  suggestion: {
    label: '',
    placeholder: getFieldLabel('suggestion', true),
  },
  sign: {
    label: '',
    signFormat: generalWithTranslations.invalidSign,
    patternSignFormat: REGEXP_SIGN_FORMAT,
    required: generalWithTranslations.required,
  },
  returnComment: {
    label: '',
    placeholder: getFieldLabel('comment', true),
    max: 512,
    maxText: generalWithTranslations.errorMax,
  },
  claimImages: {
    label: '',
    placeholder: getFieldLabel('images', true),
    min: 2,
    minText: generalWithTranslations.imagesMin,
  },
  companyTitle: {
    label: '',
    placeholder: getFieldLabel('company_name', true),
    required: generalWithTranslations.required,
  },
  cause: {
    label: '',
    required: generalWithTranslations.required,
  },
  additionalCause: {
    label: '',
    required: generalWithTranslations.required,
  },
  customer: {
    label: '',
    required: generalWithTranslations.required,
    placeholder: getFieldLabel('customer', true),
  },
  tourPointName: {
    label: '',
    placeholder: getFieldLabel('tour_point_name', true),
    required: generalWithTranslations.required,
  },
  endPoint: {
    label: '',
    required: generalWithTranslations.required,
  },
  addressWaypoint: {
    label: '',
    placeholder: getFieldLabel('address_waypoint', true),
    required: generalWithTranslations.required,
  },
  locationPoint: {
    label: '',
    placeholder: getFieldLabel('location_point', true),
    required: generalWithTranslations.required,
  },
  quantity: {
    label: '',
    required: generalWithTranslations.required,
  },
  name: {
    label: '',
    placeholder: getFieldLabel('name', true),
    required: generalWithTranslations.required,
    min: 2,
    minText: generalWithTranslations.errorMin,
    max: 18,
    maxText: generalWithTranslations.errorMax,
  },
  surname: {
    label: '',
    placeholder: getFieldLabel('surname', true),
    required: generalWithTranslations.required,
    min: 2,
    minText: generalWithTranslations.errorMin,
    max: 18,
    maxText: generalWithTranslations.errorMax,
  },
  deleteArticle: {
    comment: {
      label: '',
      placeholder: getFieldLabel('delete_article.comment', true),
      max: 512,
      maxText: generalWithTranslations.errorMax,
      min: 5,
      minText: generalWithTranslations.errorMin,
      required: generalWithTranslations.required,
    },
  },
  actions: {
    label: '',
    requiredOneOf: generalWithTranslations.requiredOneOf,
  },
  createCustomer: {
    title: {
      label: getFieldLabel('create_customer.title'),
      placeholder: getFieldLabel('create_customer.title', true),
      required: generalWithTranslations.required,
    },
    index: {
      label: getFieldLabel('create_customer.index'),
      placeholder: getFieldLabel('create_customer.index', true),
      required: generalWithTranslations.required,
      min: (count: number) => count,
      minText: generalWithTranslations.errorMin,
    },
    country: {
      label: getFieldLabel('create_customer.country'),
      placeholder: getFieldLabel('create_customer.country', true),
      required: generalWithTranslations.required,
    },
    city: {
      label: getFieldLabel('create_customer.city'),
      placeholder: getFieldLabel('create_customer.city', true),
      required: generalWithTranslations.required,
    },
    street: {
      label: getFieldLabel('create_customer.street'),
      placeholder: getFieldLabel('create_customer.street', true),
      required: generalWithTranslations.required,
      min: 2,
      minText: generalWithTranslations.errorMin,
    },
    houseNumber: {
      label: getFieldLabel('create_customer.house_number'),
      placeholder: getFieldLabel('create_customer.house_number', true),
      required: generalWithTranslations.required,
    },
    phonePrefix: {
      label: '',
      placeholder: generalWithTranslations.defaultSelectPlaceholder,
      required: generalWithTranslations.required,
    },
    phoneNumber: {
      label: getFieldLabel('create_customer.phone_number'),
      placeholder: getFieldLabel('create_customer.phone_number', true),
      required: generalWithTranslations.required,
      min: 7,
      minText: generalWithTranslations.errorMin,
      max: 12,
      maxText: generalWithTranslations.errorMax,
      pattern: REGEXP_INTEGER,
      invalid: generalWithTranslations.integer,
    },
    notes: {
      label: getFieldLabel('create_customer.notes'),
      placeholder: getFieldLabel('create_customer.notes', true),
    },
    businessType: {
      label: getFieldLabel('create_customer.business_type'),
      placeholder: getFieldLabel('create_customer.business_type', true),
      required: generalWithTranslations.required,
    },
    label: {
      label: getFieldLabel('create_customer.label'),
      placeholder: getFieldLabel('create_customer.label', true),
      required: generalWithTranslations.required,
    },
    quantityEmployees: {
      label: getFieldLabel('create_customer.quantity_employees'),
      placeholder: getFieldLabel('create_customer.quantity_employees', true),
      required: generalWithTranslations.required,
    },
    zone: {
      label: getFieldLabel('create_customer.zone'),
      placeholder: getFieldLabel('create_customer.zone', true),
      required: generalWithTranslations.required,
    },
    sector: {
      label: getFieldLabel('create_customer.sector'),
      placeholder: getFieldLabel('create_customer.sector', true),
      required: generalWithTranslations.required,
    },
  },
  updateCustomer: {
    title: {
      label: getFieldLabel('update_customer.title'),
      placeholder: getFieldLabel('update_customer.title', true),
      required: generalWithTranslations.required,
    },
    index: {
      label: getFieldLabel('update_customer.index'),
      placeholder: getFieldLabel('update_customer.index', true),
      pattern: REGEXP_INTEGER,
      required: generalWithTranslations.required,
      invalid: generalWithTranslations.integer,
      min: 5,
      minText: generalWithTranslations.errorMin,
      max: 5,
      maxText: generalWithTranslations.errorMax,
    },
    country: {
      label: getFieldLabel('update_customer.country'),
      placeholder: getFieldLabel('update_customer.country', true),
      required: generalWithTranslations.required,
    },
    city: {
      label: getFieldLabel('update_customer.city'),
      placeholder: getFieldLabel('update_customer.city', true),
      required: generalWithTranslations.required,
    },
    street: {
      label: getFieldLabel('update_customer.street'),
      placeholder: getFieldLabel('update_customer.street', true),
      required: generalWithTranslations.required,
      min: 2,
      minText: generalWithTranslations.errorMin,
    },
    houseNumber: {
      label: getFieldLabel('update_customer.house_number'),
      placeholder: getFieldLabel('update_customer.house_number', true),
      required: generalWithTranslations.required,
    },
    vat: {
      label: getFieldLabel('update_customer.vat'),
      placeholder: getFieldLabel('update_customer.vat', true),
      required: generalWithTranslations.required,
    },
    email: {
      label: getFieldLabel('update_customer.email'),
      placeholder: getFieldLabel('update_customer.email', true),
      required: generalWithTranslations.required,
    },
    position: {
      label: getFieldLabel('update_customer.position'),
      placeholder: getFieldLabel('update_customer.position', true),
      max: 18,
      maxText: generalWithTranslations.errorMax,
    },
    phoneNumber: {
      label: getFieldLabel('update_customer.phone_number'),
      placeholder: getFieldLabel('update_customer.phone_number', true),
      required: generalWithTranslations.required,
      min: 7,
      minText: generalWithTranslations.errorMin,
      max: 12,
      maxText: generalWithTranslations.errorMax,
      pattern: REGEXP_INTEGER,
      invalid: generalWithTranslations.integer,
    },
    notes: {
      label: getFieldLabel('update_customer.notes'),
      placeholder: getFieldLabel('update_customer.notes', true),
    },
    businessType: {
      label: getFieldLabel('update_customer.business_type'),
      placeholder: getFieldLabel('update_customer.business_type', true),
      required: generalWithTranslations.required,
    },
    label: {
      label: getFieldLabel('update_customer.label'),
      placeholder: getFieldLabel('update_customer.label', true),
      required: generalWithTranslations.required,
    },
    quantityEmployees: {
      label: getFieldLabel('update_customer.quantity_employees'),
      placeholder: getFieldLabel('update_customer.quantity_employees', true),
      required: generalWithTranslations.required,
    },
  },
  replanVisit: {
    comment: {
      label: '',
      placeholder: getFieldLabel('comment', true),
      max: 512,
      maxText: generalWithTranslations.errorMax,
      min: 5,
      minText: generalWithTranslations.errorMin,
      required: generalWithTranslations.required,
    },
  },
  createReport: {
    actions: {
      placeholder: getFieldLabel('create_report.actions', true),
      requiredOneOf: generalWithTranslations.requiredOneOf,
    },
    businessActions: {
      placeholder: getFieldLabel('create_report.business_actions', true),
      requiredOneOf: generalWithTranslations.requiredOneOf,
    },
    comment: {
      placeholder: getFieldLabel('create_report.comment', true),
      required: generalWithTranslations.required,
      max: 512,
      maxText: generalWithTranslations.errorMax,
      min: 5,
      minText: generalWithTranslations.errorMin,
    },
  },
  createFarAwayReport: {
    comment: {
      placeholder: getFieldLabel('create_far_away_report.comment', true),
      required: generalWithTranslations.required,
      min: 5,
      minText: generalWithTranslations.errorMin,
      max: 512,
      maxText: generalWithTranslations.errorMax,
    },
  },
  customerIssue: {
    reason: {
      label: getFieldLabel('customer_issue.reason'),
      placeholder: getFieldLabel('customer_issue.reason', true),
      required: generalWithTranslations.required,
    },
    clarification: {
      label: getFieldLabel('customer_issue.clarification'),
      placeholder: getFieldLabel('customer_issue.clarification', true),
      required: generalWithTranslations.required,
    },
    order: {
      label: getFieldLabel('customer_issue.order'),
      placeholder: getFieldLabel('customer_issue.order', true),
      required: generalWithTranslations.required,
    },
    correctAddress: {
      label: getFieldLabel('customer_issue.correct_address'),
      placeholder: getFieldLabel('customer_issue.correct_address', true),
      required: generalWithTranslations.required,
    },
    article: {
      label: getFieldLabel('customer_issue.article'),
      placeholder: getFieldLabel('customer_issue.article', true),
      required: generalWithTranslations.required,
    },
    orderProduct: {
      label: getFieldLabel('customer_issue.order_product'),
      placeholder: getFieldLabel('customer_issue.order_product', true),
      required: generalWithTranslations.required,
    },
    brand: {
      label: getFieldLabel('customer_issue.brand'),
      placeholder: getFieldLabel('customer_issue.brand', true),
      required: generalWithTranslations.required,
    },
    vin: {
      label: getFieldLabel('customer_issue.vin'),
      placeholder: getFieldLabel('customer_issue.vin', true),
      required: generalWithTranslations.required,
    },
    vinOrPlateNumber: {
      label: getFieldLabel('customer_issue.vin_or_plate_number'),
      placeholder: getFieldLabel('customer_issue.vin_or_plate_number', true),
      required: generalWithTranslations.required,
    },
    images: {
      label: getFieldLabel('customer_issue.images'),
      placeholder: getFieldLabel('customer_issue.images', true),
      required: generalWithTranslations.required,
      min: 1,
      minText: generalWithTranslations.imagesMin,
    },
    comment: {
      label: getFieldLabel('customer_issue.comment'),
      placeholder: getFieldLabel('customer_issue.comment', true),
      reasonPlaceholder: getFieldLabel('customer_issue.comment.reason', true),
      required: generalWithTranslations.required,
      min: 3,
      minText: generalWithTranslations.errorMin,
      max: 512,
      maxText: generalWithTranslations.errorMax,
    },
  },
  trackNumber: {
    shipmentNumber: {
      label: getFieldLabel('track_number.shipment_number'),
      placeholder: getFieldLabel('track_number.shipment_number', true),
      required: generalWithTranslations.required,
    },
  },
}
