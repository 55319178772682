import { GET_ENUMS } from 'utils/constants'

import { FiltersDrawer } from 'src/components/common/FiltersDrawer'

import { FilterStaticDataType, FilterStaticEnum, FilterStaticType, FormSubmitParams } from 'src/models'

import { useManagePointsFetch, useManagePointsFilters } from 'src/bus/manageTours'

import { useUrlQuery } from 'src/hooks'

import { useDrawer } from 'src/contexts'

export const ManageToursFilters = () => {
  const { setOnPageDrawer } = useDrawer()
  const { onUpdateQuery } = useUrlQuery()
  const { managePointsFilters, loadingManagePointsFiltersFetch } = useManagePointsFilters()
  const { loadingManagePointsFetch, managePointsFiltersStaticInitialValues, onRefetchManagePoints } =
    useManagePointsFetch()

  const filterStaticItems: FilterStaticDataType = {
    zones: {
      name: FilterStaticEnum.zones,
      options: managePointsFilters.zones,
      isMulti: true,
    },
    sectors: {
      name: FilterStaticEnum.sectors,
      options: managePointsFilters.sectors,
      isMulti: true,
    },
    profileExists: {
      name: FilterStaticEnum.profileExists,
      options: managePointsFilters.profileExists,
    },
    customerStatus: {
      name: FilterStaticEnum.customerStatus,
      options: managePointsFilters.customerStatus,
      isMulti: true,
    },
    potentials: {
      name: FilterStaticEnum.potentials,
      options: managePointsFilters.potential,
      isMulti: true,
    },
    businessType: {
      name: FilterStaticEnum.businessType,
      options: managePointsFilters.businessType,
      isMulti: true,
    },
    quantityEmployees: {
      name: FilterStaticEnum.quantityEmployees,
      options: managePointsFilters.quantityEmployees,
      isMulti: true,
    },
    plannedVisitsExist: {
      name: FilterStaticEnum.plannedVisitsExist,
      options: managePointsFilters.plannedVisitsExistence,
      isVisible: false,
    },
    date: {
      name: FilterStaticEnum.date,
    },
  }

  const onSubmit = async (data: FormSubmitParams<FilterStaticType>) => {
    const { filterData } = onUpdateQuery({
      filtersValues: data.values,
      filterItems: filterStaticItems,
    })

    await onRefetchManagePoints(filterData)
    setOnPageDrawer(GET_ENUMS.drawer.filters, false)
  }

  return (
    <FiltersDrawer
      onSubmit={onSubmit}
      filterItemsSet={filterStaticItems}
      buttons={['apply', 'reset']}
      loadingSubmit={loadingManagePointsFetch}
      initialValues={managePointsFiltersStaticInitialValues?.filter}
      loadingStaticDataFetch={loadingManagePointsFiltersFetch}
    />
  )
}
