import { TourPlanForTodayType } from 'src/models'

import { tourKeys, tourService } from 'src/bus/tour'

import { useQueryAsync } from 'src/hooks'

type UseTourPlanForTodayFetchType = {
  loadingPlanForTodayFetch: boolean
  planForToday: TourPlanForTodayType | null
}

const FIFTEEN_MINUTES = 60 * 15 * 1000

export const useTourPlanForTodayFetch = (): UseTourPlanForTodayFetchType => {
  const fetchTourPlanForTodayProps = useQueryAsync<TourPlanForTodayType>({
    key: tourKeys.planForToday(),
    name: 'fetchTourPlanForToday',
    fetcher: tourService.fetchPlanForToday,
    options: { refetchInterval: FIFTEEN_MINUTES },
  })

  return {
    loadingPlanForTodayFetch: fetchTourPlanForTodayProps.isLoading,
    planForToday: fetchTourPlanForTodayProps.data || null,
  }
}
