import { ReactNode } from 'react'
import { z } from 'zod'

export enum StaticDataKeys {
  languages = 'languages',
  countries = 'countries',
  cancelingReasons = 'canceling-reasons',
  claimCauses = 'causes',
  additionalCauses = 'additional-causes',
  businessType = 'business-type',
  quantityEmployees = 'quantity-employees',
  phoneCodes = 'phone-codes',
  planVisits = 'plan-visits',
  customerStatus = 'customer-status',
  parcelStatus = 'parcel-status',
  parcelType = 'parcel-type',
  parcelArticleStatus = 'parcel-article-status',
  communicationType = 'communication-type',
  navigator = 'navigator',
  potential = 'potential',
  visitStatus = 'visit-status',
  businessAction = 'business-action',
  customerHistoryType = 'customer-history-type',
  otrsTicketReasons = 'otrs-ticket-reasons',
  acceptance = 'acceptance',
  plannedVisitsExistence = 'planned-visits-existence',
  customerLabels = 'customer-labels',

  profileExists = 'profile-exists',
  customers = 'customers',
  sectors = 'sectors',
  zones = 'zones',
}

export type StaticDataType =
  | StaticDataKeys.languages
  | StaticDataKeys.countries
  | StaticDataKeys.cancelingReasons
  | StaticDataKeys.claimCauses
  | StaticDataKeys.businessType
  | StaticDataKeys.phoneCodes
  | StaticDataKeys.quantityEmployees
  | StaticDataKeys.planVisits
  | StaticDataKeys.customerStatus
  | StaticDataKeys.parcelStatus
  | StaticDataKeys.additionalCauses
  | StaticDataKeys.parcelType
  | StaticDataKeys.parcelArticleStatus
  | StaticDataKeys.communicationType
  | StaticDataKeys.navigator
  | StaticDataKeys.potential
  | StaticDataKeys.visitStatus
  | StaticDataKeys.businessAction
  | StaticDataKeys.customerHistoryType
  | StaticDataKeys.otrsTicketReasons
  | StaticDataKeys.acceptance
  | StaticDataKeys.plannedVisitsExistence
  | StaticDataKeys.customerLabels
  | StaticDataKeys.sectors
  | StaticDataKeys.zones
  | StaticDataKeys.customers
  | StaticDataKeys.profileExists

export type StaticDataFetchQueryParams = {
  types: StaticDataType[]
}

export const selectSchema = z.object({
  id: z.number(),
  value: z.string(),
  label: z.string(),
  icon: z.string().optional(),
  options: z
    .array(
      z.object({
        id: z.number(),
        value: z.string(),
        label: z.string(),
      }),
    )
    .optional(),
})

export const selectSchemaNullable = selectSchema.nullable()

export const selectListSchema = selectSchema.array()

export const selectItemAutocompleteSchema = selectSchema
  .omit({ label: true })
  .extend({
    label: z.string().or(z.custom<ReactNode>()).optional(),
  })
  .nullable()

export type SelectItem = z.infer<typeof selectSchemaNullable>
export type SelectItemAutocomplete = z.infer<typeof selectItemAutocompleteSchema>

export type DefaultAutocompleteOptionType = NonNullable<SelectItemAutocomplete>

export type StaticDataItemType = z.infer<typeof selectSchema>

export type StaticDataInnerShape = {
  [K in StaticDataType]: StaticDataItemType[]
}

export type StaticDataItemsType = {
  [key in keyof typeof StaticDataKeys]?: StaticDataItemType[]
}

export type StaticDataFetchParams = {
  params: StaticDataFetchQueryParams
}

export type StaticDataZonesType = { zones: StaticDataItemType[] }
export type StaticDataSectorsType = { sectors: StaticDataItemType[] }
