import { StaticDataItemsType } from 'src/models'

import { useParcelsStaticDataFetch } from 'src/bus/parcels'

type UseParcelsFiltersType = {
  loadingParcelsFiltersFetch: boolean
  parcelsFilters: StaticDataItemsType
}

export const useParcelsFilters = (): UseParcelsFiltersType => {
  const { parcelsFilters, loadingParcelsStaticDataFetch } = useParcelsStaticDataFetch()

  const filters = { parcelStatus: parcelsFilters.parcelStatus, parcelType: parcelsFilters.parcelType }

  return {
    parcelsFilters: filters,
    loadingParcelsFiltersFetch: loadingParcelsStaticDataFetch,
  }
}
