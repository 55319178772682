import { z } from 'zod'

import { hasID, emailSchema, metaSchema, locationSchema } from './Base'
import {
  HttpRequestParams,
  BaseDataInfiniteType,
  StaticDataItemType,
  LocationType,
  selectSchemaNullable,
} from './index'

export const customerLoyaltySchema = z
  .object({
    currentAmount: z.number(),
    diff: z.number(),
    minScale: z.number(),
    maxScale: z.number(),
    nextStatus: z.string(),
    dateEnd: z.number(),
  })
  .nullable()

export const contactPersonPhoneSchema = z.object({
  id: z.number().nullable(),
  phoneNumber: z.string(),
  phonePrefix: selectSchemaNullable,
})

export const customerContactPersonSchema = z.object({
  id: z.number().nullable(),
  name: z.string(),
  surname: z.string(),
  position: z.string(),
  phones: contactPersonPhoneSchema.array(),
})

export const customerBaseSchema = z.object({
  awsId: z.number().nullable(),
  title: z.string(),
  companyTitle: z.string().nullable(),
  status: selectSchemaNullable,
  country: selectSchemaNullable,
  city: z.string(),
  street: z.string(),
  index: z.string(),
  houseNumber: z.string(),
  phonePrefix: selectSchemaNullable,
  phoneNumber: z.string(),
  address: z.string(),
  loyaltyProgram: customerLoyaltySchema,
  marketType: z.string(),
  vat: z.string(),
  email: emailSchema,
  notes: z.string(),
  sector: z.string(),
  customerLabel: selectSchemaNullable,
  daysFromLastVisit: z.number().nullable(),
  businessType: selectSchemaNullable,
  quantityEmployees: selectSchemaNullable,
  contactPersons: customerContactPersonSchema.array(),
  isHasLeadInvitedCredential: z.boolean(),
  easyRetailLocation: locationSchema.nullable(),
  realLocation: locationSchema.nullable(),
  isClosed: z.boolean(),
  nextPlanedVisit: z
    .object({
      id: z.number(),
      date: z.number(),
      employee: z.string(),
      employeeRole: z.string(),
    })
    .nullable(),
})

export const kamCustomerSchema = z.object({
  customerGroupName: z.string(),
  group: selectSchemaNullable,
  subGroupType: selectSchemaNullable,
  groupSize: z.string(),
  contract: z.string(),
})

export const customerSchema = customerBaseSchema.merge(hasID)

export const customerVatVerifySchema = z.object({
  legalName: z.string(),
  legalAddress: z.string(),
  vat: z.string(),
  status: z.enum(['idle', 'valid', 'invalid']),
})

export const customerCredentialSchema = z.object({
  login: z.string(),
  password: z.string(),
})

export const customerListSchema = z.object({
  items: customerSchema.array(),
  meta: metaSchema,
})

export type CustomerLoyaltyType = z.infer<typeof customerLoyaltySchema>
export type CustomerType = z.infer<typeof customerSchema>
export type CustomersType = z.infer<typeof customerListSchema>
export type CustomerVatVerifyType = z.infer<typeof customerVatVerifySchema>
export type CustomerCredentialsType = z.infer<typeof customerCredentialSchema>
export type ContactPersonPhoneType = z.infer<typeof contactPersonPhoneSchema>

export type KamCustomerType = z.infer<typeof kamCustomerSchema>

// export type CustomerCredentialsType = {
//   login: string;
//   password: string;
// };

// export type CustomerVatStatusType = "idle" | "valid" | "invalid";

// export type CustomerVatVerifyType = {
//   legalName: string;
//   legalAddress: string;
//   vat: string;
//   status: CustomerVatStatusType;
// };

export type CustomersStaticListType = StaticDataItemType[]

// export type CustomersType = BaseDataListType<CustomerType>;
export type CustomersInfiniteType = BaseDataInfiniteType<CustomerType>
export type CustomerParams = HttpRequestParams<
  | CustomerType
  | {
      notes: string
    }
  | {
      vat: string
    }
  | {
      isClosed: boolean
    }
  | {
      realLocation: LocationType | null
    }
>
