import { StaticDataItemsType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseHistoryStaticDataFetchType = {
  historyFilters: StaticDataItemsType
  loadingHistoryStaticDataFetch: boolean
}

export const useHistoryStaticDataFetch = (): UseHistoryStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.communicationType, StaticDataKeys.customerHistoryType]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const historyFilters = {
    communicationType: staticData?.['communication-type'] || [],
    customerHistoryType: staticData?.['customer-history-type'] || [],
  }

  return {
    loadingHistoryStaticDataFetch: loadingStaticDataFetch,
    historyFilters,
  }
}
