import { StaticDataItemsType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type UseParcelsStaticDataFetchType = {
  parcelsFilters: StaticDataItemsType
  loadingParcelsStaticDataFetch: boolean
}

export const useParcelsStaticDataFetch = (): UseParcelsStaticDataFetchType => {
  const staticDataKeys = [StaticDataKeys.parcelStatus, StaticDataKeys.parcelType, StaticDataKeys.parcelArticleStatus]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const parcelsFilters = {
    parcelStatus: staticData?.['parcel-status'] || [],
    parcelType: staticData?.['parcel-type'] || [],
    parcelArticleStatus: staticData?.['parcel-article-status'] || [],
  }

  return {
    loadingParcelsStaticDataFetch: loadingStaticDataFetch,
    parcelsFilters,
  }
}
