import type { TFunction } from 'i18next'
import { z } from 'zod'

import { messages, OPTION_OBJECT } from 'src/utils'

type TCreateCustomerSchema = {
  t: TFunction
  country: string
}

const getMinIndexCountByCountry = (country: string) => {
  return (
    {
      fr: 5,
      de: 5,
      nl: 4,
      at: 4,
    }[country] || 5
  )
}

export const createCustomer = {
  shape: {
    title: '',
    index: '',
    city: '',
    street: '',
    house: '',
    notes: '',
    businessType: null,
    customerLabel: null,
    quantityEmployees: null,
    zone: null,
    sector: null,
  },
  schema: ({ t, country }: TCreateCustomerSchema) => {
    const indexMinCount = getMinIndexCountByCountry(country)

    return z
      .object({
        title: z.string().trim().min(1, t(messages.createCustomer.title.required)),
        index: z
          .string()
          .trim()
          .min(
            messages.createCustomer.index.min(indexMinCount),
            t(messages.createCustomer.index.minText, {
              label: t(messages.createCustomer.index.label).toLowerCase(),
              count: messages.createCustomer.index.min(indexMinCount),
            }),
          ),
        city: z.string().trim().min(1, t(messages.createCustomer.city.required)),
        street: z
          .string()
          .trim()
          .min(
            messages.createCustomer.street.min,
            t(messages.createCustomer.street.minText, {
              label: t(messages.createCustomer.street.label).toLowerCase(),
              count: messages.createCustomer.street.min,
            }),
          ),
        house: z.string().trim().min(1, t(messages.createCustomer.houseNumber.required)),
        notes: z.string().trim(),
        businessType: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.createCustomer.houseNumber.required),
        }),
        customerLabel: z.object(OPTION_OBJECT),
        quantityEmployees: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.createCustomer.quantityEmployees.required),
        }),
        zone: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.createCustomer.zone.required),
        }),
        sector: z.object(OPTION_OBJECT, {
          invalid_type_error: t(messages.createCustomer.sector.required),
        }),
      })
      .strict()
  },
}
