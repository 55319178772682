import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { includes } from 'ramda'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { FiltersStaticItem } from 'src/components/common'
import { Button, Form, FormFields, Loader } from 'src/components/ui'

import {
  FormSubmitFn,
  FiltersButtonType,
  FilterStaticRequestValuesType,
  FilterStaticBaseConfigType,
  FilterStaticDataType,
  FilterStaticType,
} from 'src/models'

import { formShapes } from 'src/bus/forms'

import { createFilterStaticBaseConfig, getFilterOptionsIsEmpty, prepareFilterStaticInitialValues } from 'src/utils'

type FiltersDynamicFormType = {
  initialValues?: FilterStaticRequestValuesType | null
  onSubmit: FormSubmitFn<FilterStaticType>
  buttons?: FiltersButtonType[]
  filterItemsSet?: FilterStaticDataType
  loadingFetch?: boolean
  loadingSubmit?: boolean
  loadingStaticDataFetch?: boolean
  scroll?: boolean
}

export const FiltersStaticForm = ({
  onSubmit,
  initialValues = null,
  buttons = [],
  loadingSubmit = false,
  loadingFetch = false,
  loadingStaticDataFetch = false,
  filterItemsSet = {},
  scroll = true,
}: FiltersDynamicFormType) => {
  const { t } = useTranslation()

  const filtersInitialValues = { ...formShapes.staticFiltersForm.shape(filterItemsSet) }

  const formProps = useForm<FilterStaticType>({
    defaultValues: filtersInitialValues,
    resolver: zodResolver(formShapes.staticFiltersForm.schema(filterItemsSet)),
  })

  const isFilterOptionsEmpty = getFilterOptionsIsEmpty(filterItemsSet)

  const { control, handleSubmit, setError, reset } = formProps

  const onSubmitForm = handleSubmit(async (values) => {
    await onSubmit({ values, acts: { setError, reset } })
  })

  const filtersConfig = createFilterStaticBaseConfig(
    Object.keys(filterItemsSet) as Array<keyof FilterStaticBaseConfigType>,
    filterItemsSet,
  )

  const filtersList = Object.keys(filtersConfig) as Array<keyof FilterStaticBaseConfigType>

  const hasDisabledBtn = loadingStaticDataFetch || loadingFetch

  useDeepCompareEffect(() => {
    const data = initialValues && prepareFilterStaticInitialValues(initialValues, filterItemsSet)

    reset({ ...filtersInitialValues, ...data })
  }, [initialValues, reset, filterItemsSet])

  const onResetForm = async () => {
    reset(filtersInitialValues)
    await onSubmit({ values: filtersInitialValues, acts: { setError, reset } })
  }

  const hasApply = includes('apply', buttons)
  const hasReset = includes('reset', buttons)

  const buttonApply = (
    <Button type='submit' block disabled={hasDisabledBtn || isFilterOptionsEmpty} loading={loadingSubmit}>
      {t('buttons:apply')}
    </Button>
  )

  const buttonReset = (
    <Button variant='outline' block disabled={hasDisabledBtn || isFilterOptionsEmpty} onClick={onResetForm}>
      {t('buttons:reset_filters')}
    </Button>
  )

  const renderFooter = () => {
    if (hasApply && hasReset) {
      return (
        <div className='flex w-full flex-col gap-2 p-2'>
          {buttonApply}
          {buttonReset}
        </div>
      )
    }

    return <div className='p-2'>{buttonApply || buttonReset}</div>
  }

  if (loadingStaticDataFetch) {
    return <Loader type='relative' />
  }

  return (
    <Form {...formProps}>
      <FormFields.FormGeneral onSubmit={onSubmitForm} scroll={scroll} footer={renderFooter()}>
        {filtersList.map((filterKey) => {
          const filterItem = filtersConfig[filterKey]

          console.log('filterItem', filterItem)

          return (
            <FiltersStaticItem
              key={filterKey}
              control={control}
              loadingFetchStaticData={loadingStaticDataFetch}
              type={filterKey}
              hasDisabled={loadingSubmit}
              {...filterItem}
            />
          )
        })}
      </FormFields.FormGeneral>
    </Form>
  )
}
