import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import { TourOptimizeRouteType, TourParamsType } from 'src/models'

import { tourService, tourKeys, useTourPlanForTodayFetch } from 'src/bus/tour'
import { useVisitsFetch, visitsKeys } from 'src/bus/visits'

import { useCurrentLocation } from 'src/hooks'

import { formatDate, isNotNilOrNotEmpty, queryFetcher } from 'src/utils'

type UseTourRouteOptimizeType = {
  onTourRouteOptimize: () => void
  loadingTourRouteOptimize: boolean
  isOptimizationAvailable: boolean
}

const TWO_MINUTES = 60 * 2 * 1000

export const useTourRouteOptimize = (): UseTourRouteOptimizeType => {
  const queryClient = useQueryClient()
  const { geoLocation, timestamp } = useCurrentLocation()
  const { visits, loadingVisitsFetch } = useVisitsFetch()
  const { planForToday, loadingPlanForTodayFetch } = useTourPlanForTodayFetch()

  const [disabled, setDisabled] = useState(false)

  const optimizeTourRouteProps = useMutation<void, TourOptimizeRouteType, TourParamsType>({
    mutationKey: tourKeys.tourRoute(geoLocation),
    mutationFn: (params) => {
      return queryFetcher({
        name: 'optimizeTourRoute',
        fetcher: tourService.optimizeTourRoute,
        fetcherPayload: params,
      })
    },
  })

  const onTourRouteOptimize = useCallback(async () => {
    if (!geoLocation) {
      return
    }

    setDisabled(true)

    optimizeTourRouteProps.mutate(
      {
        data: {
          ...geoLocation,
          time: formatDate(timestamp, 'dashReversedTime'),
        },
      },
      {
        onSettled: async () => {
          await queryClient.invalidateQueries({ queryKey: tourKeys.all })
          geoLocation && (await queryClient.refetchQueries({ queryKey: tourKeys.tourRoute(geoLocation) }))
          await queryClient.refetchQueries({ queryKey: visitsKeys.list() })
        },
      },
    )

    setTimeout(() => {
      setDisabled(false)
    }, TWO_MINUTES)
  }, [optimizeTourRouteProps, geoLocation, timestamp, queryClient])

  // useEffect(() => {
  //   if (!!planForToday?.isTourOptimized || isNilOrEmpty(visits.items)) {
  //     return
  //   }
  //
  //   onTourRouteOptimize()
  // }, [planForToday?.isTourOptimized, visits.items])

  return {
    onTourRouteOptimize,
    loadingTourRouteOptimize: optimizeTourRouteProps.isPending || loadingVisitsFetch || loadingPlanForTodayFetch,
    isOptimizationAvailable: !!planForToday?.isOptimizationAvailable && isNotNilOrNotEmpty(visits.items) && !disabled,
  }
}
