import { StaticDataItemsType, StaticDataKeys } from 'src/models'

import { useStaticDataFetch } from 'src/bus/staticData'

type CustomersStaticDataFetchType = {
  loadingCustomerStaticDataFetch: boolean
  customersFilters: StaticDataItemsType
}

export const useCustomersStaticDataFetch = (): CustomersStaticDataFetchType => {
  const staticDataKeys = [
    StaticDataKeys.countries,
    StaticDataKeys.phoneCodes,
    StaticDataKeys.quantityEmployees,
    StaticDataKeys.businessType,
    StaticDataKeys.planVisits,
    StaticDataKeys.customerStatus,
    StaticDataKeys.potential,
    StaticDataKeys.customerLabels,
  ]

  const { staticData, loadingStaticDataFetch } = useStaticDataFetch(staticDataKeys)

  const customersFilters = {
    countries: staticData?.countries || [],
    phoneCodes: staticData?.['phone-codes'] || [],
    quantityEmployees: staticData?.['quantity-employees'] || [],
    businessType: staticData?.['business-type'] || [],
    planVisits: staticData?.['plan-visits'] || [],
    customerStatus: staticData?.['customer-status'] || [],
    potential: staticData?.['potential'] || [],
    customerLabels: staticData?.['customer-labels'] || [],
  }

  return {
    loadingCustomerStaticDataFetch: loadingStaticDataFetch,
    customersFilters,
  }
}
