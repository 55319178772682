import { StaticDataItemsType } from 'src/models'

import { useVisitsHistoryStaticDataFetch } from 'src/bus/visitsHistory'

type UseVisitsFiltersType = {
  loadingVisitsFiltersFetch: boolean
  visitsFilters: StaticDataItemsType
}

export const useVisitsFilters = (): UseVisitsFiltersType => {
  const { visitsHistoryFilters, loadingVisitsStaticDataFetch } = useVisitsHistoryStaticDataFetch()

  const visitsFilters = {
    visitStatus: visitsHistoryFilters.visitStatus,
    sectors: visitsHistoryFilters.sectors,
    customers: visitsHistoryFilters.customers,
    customerStatus: visitsHistoryFilters.customerStatus,
  }

  return {
    visitsFilters,
    loadingVisitsFiltersFetch: loadingVisitsStaticDataFetch,
  }
}
