import { z } from 'zod'

import { locationSchema, metaSchema } from './Base'
import { serverErrorBaseSchema } from './Http'
import { HttpServerRequestParams, BaseDataListType, LocationType } from './index'

export type TourParamsType = HttpServerRequestParams

export type News = {
  id: number
  title: string
  description: string
  imageUrl: string
}

export type TourPlace = google.maps.LatLngLiteral
const tourPlaceSchema: z.ZodType<TourPlace> = locationSchema

export type TourNewsTypes = BaseDataListType<News>

export const tourRouteSchema = z.object({
  id: z.number(),
  title: z.string(),
  visitId: z.number(),
  customerId: z.number(),
  location: tourPlaceSchema,
  groupAlias: z.enum(['active', 'notActive', 'lead', 'closed']).nullable(),
  daysFromLastVisit: z.number().nullable(),
})

export const tourRouteListSchema = z.object({
  items: tourRouteSchema.array(),
  meta: metaSchema.optional(),
})

export const planForTodayMetricsSchema = z.object({
  visits: z.number().optional(),
  returns: z.number().optional(),
  loyalty: z.number().optional(),
  conquest: z.number().optional(),
  prospect: z.number().optional(),
  conqProsp: z.number().optional(),
})

export const planForTodayProgressSchema = z.object({
  current: z.number(),
  visits: z.object({
    completed: z.number(),
    all: z.number(),
  }),
})

export const tourPlanForTodaySchema = z.object({
  tourId: z.number().optional(),
  sectors: z.array(z.string()),
  metrics: planForTodayMetricsSchema,
  progress: planForTodayProgressSchema,
  estimation: z.object({
    duration: z.string(),
    finishTime: z.string(),
  }),
  isTourOptimized: z.boolean(),
  isOptimizationAvailable: z.boolean(),
  errors: serverErrorBaseSchema.array().nullable(),
})

export type PlanForTodayProgress = z.infer<typeof planForTodayProgressSchema>
export type PlanForTodayMetrics = z.infer<typeof planForTodayMetricsSchema>
export type TourPlanForTodayType = z.infer<typeof tourPlanForTodaySchema>
export type TourRoute = z.infer<typeof tourRouteSchema>
export type TourRoutesType = z.infer<typeof tourRouteListSchema>

export type TourRoutes = {
  origin: string | google.maps.LatLng | google.maps.Place | TourPlace
  destination: string | google.maps.LatLng | google.maps.Place | TourPlace
  waypoints: google.maps.DirectionsWaypoint[]
}

export type PlanForTodayMetricIcon = {
  [K in keyof PlanForTodayMetrics]: string
}

export type TourOptimizeRouteType = LocationType & { time: string }
